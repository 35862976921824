import React from 'react';
import PropTypes from 'prop-types';

/**
 * SpinnerLoader component displays a spinning loader.
 * @param {Object} props - Component props.
 * @param {string} props.color - Color of the spinner (default: '#167db1').
 * @param {number} props.size - Size of the spinner in pixels (default: 24).
 * @returns {JSX.Element} - SpinnerLoader component.
 */
const SpinnerLoader = ({ color = '#167db1', size = 24 }) => {
    return (
        <div
            className="border-t-4 border-solid rounded-full animate-spin"
            style={{
                borderTopColor: color, // Loader color
                borderWidth: `${size / 12}px`, // Loader width (adjust based on the size)
                width: `${size}px`, // Loader width
                height: `${size}px`, // Loader height
            }}
        />
    );
};

SpinnerLoader.propTypes = {
    color: PropTypes.string, // Color of the spinner
    size: PropTypes.number, // Size of the spinner in pixels
};

export default SpinnerLoader;
