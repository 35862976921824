import React, { lazy, Suspense } from 'react';

/**
 * Factory function to create lazy-loaded components.
 * @param {Function} importFunc - A function that returns a dynamic import of the component.
 * @param {JSX} fallbackComponent - JSX to render as a loading fallback.
 * @returns {Function} A React component that lazy-loads the specified component.
 */
const createLazyComponent = (importFunc, fallbackComponent) => {
    const LazyComponent = lazy(importFunc);

    // Return the lazy component with a loading fallback
    return (props) => (
        <Suspense fallback={fallbackComponent}>
            <LazyComponent {...props} />
        </Suspense>
    );
};

export default createLazyComponent;