import React from 'react';
import SpinnerLoader from "../../loader/SpinnerLoader";

const LoadingFallback = () => {
    return (
        <div className="flex justify-center items-center h-screen text-2xl transition-all">
            {/* Spinning loader */}
            <SpinnerLoader size={36}/>
        </div>
    );
};

export default LoadingFallback;