import React from 'react';
import ReactDOM from 'react-dom';
import AppContainer from "./containers/app/AppContainer";
import reportWebVitals from './reportWebVitals';

// Import styles and libraries

import "@fortawesome/fontawesome-free/css/all.min.css"; // Import Font Awesome icons
import "./assets/styles/tailwind.css"; // Import Tailwind CSS
import './index.css';
import BackgroundSVGComponent from "./components/presentational/background/BackgroundSVGComponent";

/**
 * Render the React application into the root HTML element.
 * @function
 * @param {JSX.Element} component - The root component of the application.
 * @param {Element} rootElement - The root HTML element to render the application into.
 * @returns {void}
 */
ReactDOM.render(
    <React.StrictMode>
            {/*<BackgroundSVGComponent/>*/}
            <AppContainer/> {/* Render the main AppContainer component */}
    </React.StrictMode>,
    document.getElementById('root') // Mount it in the 'root' HTML element
);

/**
 * Measure and report web performance (optional).
 * If you want to start measuring performance in your app, you can pass a function to log results or send to an analytics endpoint.
 * Learn more: https://bit.ly/CRA-vitals
 * @function
 * @param {Function} callback - A function to log results or send data to an analytics endpoint.
 * @returns {void}
 */
reportWebVitals();