import React from 'react';
import {BrowserRouter as Router, Switch} from 'react-router-dom';
import createLazyComponent from '../../components/factories/LazyComponentFactory';
import LoadingFallback from '../../components/presentational/fallbacks/LoadingFallback';
import {UserProvider} from "../../contexts/UserContext";
import {BreadcrumbsProvider} from "../../contexts/BreadcrumbsContext";

/**
 * Create a lazy-loaded Routes component for the entire application.
 * Lazy loading is used to improve performance by loading routes only when needed.
 */
const LazyRoutes = createLazyComponent(() => import('../../router/AppRoutes'), <LoadingFallback/>);

/**
 * The main AppContainer component that wraps the entire application.
 */
class AppContainer extends React.Component {
    // You can add state, data fetching logic, or other application-level concerns here

    /**
     * Render the AppContainer component, including the lazy-loaded Routes component.
     * @returns {JSX.Element} The rendered React component.
     */
    render() {
        return (
            <Router>
                <UserProvider>
                    <BreadcrumbsProvider>
                        {/* Render the lazy-loaded Routes component */}
                        <LazyRoutes/>
                    </BreadcrumbsProvider>
                </UserProvider>
            </Router>
        );
    }
}

export default AppContainer;