import React, {createContext, useReducer} from 'react';

// Create the context
export const BreadcrumbsContext = createContext();

const initialState = {
    url: '',
};

// Action types
const SET = 'SET';
const CLEAR = 'CLEAR';


// Reducer function
const breadcrumbsReducer = (state, action) => {
    switch (action.type) {
        case SET:
            return {
                ...state,
                url: action.payload.url,
            };
        case CLEAR:
            return initialState;
        default:
            return state;
    }
};


// BreadcrumbsProvider component to wrap your app with
const BreadcrumbsProvider = ({children}) => {
    const [state, dispatch] = useReducer(breadcrumbsReducer, initialState);

    return (
        <BreadcrumbsContext.Provider value={{state, dispatch}}>
            {children}
        </BreadcrumbsContext.Provider>
    );
};

export {BreadcrumbsProvider};